export const links = {
    "Header_Button_1": "route:home,hash:#services",
    "calendly": "https://calendly.com/moat-sales",
    "facebook": "https://www.facebook.com/yourfacebooklink",
    "instagram": "https://www.instagram.com/yourinstagramlink",
    "linkedin": "https://www.linkedin.com/company/moat-builders-ai/",
    "twitter": "https://twitter.com/yourtwitterlink",
    "NavBar_brand": "route:home",
    "NavBar_NavItem_1": "route:home,hash:#services",
    "NavBar_NavItem_2": "route:home,hash:#how-it-works",
    "NavBar_NavItem_3": "route:careers",
    "careers": "route:careers",
    "contactHero": "route:contact",
    "NavBar_Pricing_Button": "route:home,hash:#pricing",
    "Footer_Careers": "route:careers",
    "Footer_How_it_works": "route:home,hash:#how-it-works",
    "Footer_Pricing": "route:home,hash:#pricing",
    "Footer_Service": "route:home,hash:#services",
    "Footer_Privacy_Policy": "route:privacy-policy",
    "Footer_Whats_CTOaaS": "route:home,hash:#solutions-cto-as-a-service",
    "glassdoor":"https://www.glassdoor.com.br/Vis%C3%A3o-geral/Trabalhar-na-Moat-Builders-EI_IE6490195.13,26.htm"
} as any;
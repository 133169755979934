import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { defaultLocale } from '@/app-modules/cms/languagesConfig';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: `/${defaultLocale}`
  },
  {
    path: '/:locale(en-GB|en-AU)',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:locale(en-GB|en-AU)/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:locale(en-GB|en-AU)/careers',
    name: 'careers',
    component: () => import(/* webpackChunkName: "careers" */ '../views/CareersView.vue')
  },
  {
    path: '/:locale(en-GB|en-AU)/careers/:slug',
    name: 'opportunity',
    component: () => import(/* webpackChunkName: "opportunity" */ '../views/OpportunityView.vue')
  },
  {
    path: '/:locale(en-GB|en-AU)/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/:locale(en-GB|en-AU)/contact/:opt?',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/:locale(en-GB|en-AU)/thanks',
    name: 'thanks',
    component: () => import(/* webpackChunkName: "content" */ '../views/ThankView.vue')
  },
  {
    path: '/:locale(en-GB|en-AU)/confirmation',
    name: 'confirmation',
    component: () => import(/* webpackChunkName: "confirmation" */ '../views/ConfirmationView.vue')
  },
  // Catch-all route to handle unmatched paths and redirect to default locale
  {
    path: '/:pathMatch(.*)*',
    redirect: `/${defaultLocale}`
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.hash !== '#') {
      setTimeout(() => {
        const targetElement = document.querySelector(to.hash);
        if (targetElement && targetElement instanceof HTMLElement) {
          const menuBarElement = document.getElementById('globalNav');
          const offset = menuBarElement instanceof HTMLElement ? menuBarElement.offsetHeight : 0;

          window.scrollTo({
            top: targetElement.offsetTop - offset,
            behavior: 'smooth',
          });
        }
      }, 500); // delay to wait for the render before the scroll

      return false;
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;

// Define a type for the language entries
type LanguageEntry = {
    icon: string;
    path: string;
    label: string;
    alt: string;
};

type LanguagesType = {
    [key: string]: LanguageEntry;
};

export const defaultLocale = 'en-GB';

export const languages: LanguagesType = {
    "en-GB": {
        "icon": "/img/icons/icons8-great-britain-48.png",
        "path": "en-GB",
        "label": "English (UK) - GBP",
        "alt": "GB-icon"
    },
    "en-AU": {
        "icon": "/img/icons/icons8-australia-48.png",
        "path": "en-AU",
        "label": "English (AU) - AUD",
        "alt": "AU-icon"
    },
    // "pt-BR": {
    //   "icon":"/img/icons/icons8-brazil-48.png",
    //   "path":"pt-br",
    //   "label":"Português (BR) - BRL",
    //   "alt":"PT-icon"
    // },
};

export const languagesDict = Object.keys(languages).reduce((acc: { [key: string]: string }, key) => {
    const path = languages[key].path.toLowerCase();
    acc[path] = key;
    return acc;
}, {});
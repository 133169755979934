
import {defineComponent} from 'vue';
import {UrlManipulator} from '@/router/UrlManipulator';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    externalLink: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      required: true
    },
    dataLink: {
      type: String,
      required: true
    },
    dataSlug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      urlManipulator: new UrlManipulator()
    };
  },
  methods: {
    goToPath(path: string = '', hash: string = '', locale: string = ''): void {
      if (this.externalLink) {
        window.location.href = this.link;
        return;
      }

      this.urlManipulator.goToPath(this.link, hash, locale);
    }
  }
});

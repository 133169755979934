export const en = {
    "404": "404 - Not Found",
    "contact_form_label_1": "Full Name",
    "contact_form_placeholder_1": "Your Name",
    "contact_form_label_2": "Company Name",
    "contact_form_placeholder_2": "Moat Builders",
    "contact_form_label_3": "Email Address",
    "contact_form_placeholder_3": "email@email.com",
    "contact_form_label_4": "Tell us about your needs",
    "contact_form_placeholder_4": "Develop a technology roadmap, build an app for your service, improve MVP...",
    "contact_form_label_5": "Did you find your plan?",
    "contact_form_placeholder_5": "Select your plan",
    "contact_form_sel_opt_0": "Select your plan",
    "contact_form_sel_opt_1": "50h plan",
    "contact_form_sel_opt_2": "100h plan",
    "contact_form_sel_opt_3": "500h plan",
    "contact_form_sel_opt_4": "I didn't find my plan yet",
    "contact_form_validation_feedback_1": "Check the information provided in this field and try again.",
    "contact_form_validation_feedback_2": "Check the information provided in this field and try again.",
    "contact_form_validation_feedback_3": "Please enter a valid email address.",
    "contact_form_validation_feedback_4": "Check the information provided in this field and try again.",
    "contact_form_validation_feedback_5": "Please select an option.",
    "contact_form_button": "Send",
    "contact_subscription_headline": "Read more on CTOaaS",
    "contact_subscription_paragraph": "Just browsing? Add your email to the list and receive our contents describing more this service.",
    "contact_subscription_button": "Add me to the list",
    "contact_subscription_placeholder": "Your email address",
    "contact_subscription_success": "Thank you for subscribing! We will contact you soon.",
    "contact_subscription_error": "Something went wrong. Please try again later.",
    "lead_sent": "Thank you for your interest! We will contact you soon.",
    "lead_not_sent": "Something went wrong. Please try again later.",
    "lead_not_valid": "Please enter a valid email address.",
    "2nd_column_Form_label": "Add your email",
    "2nd_column_Button": "Submit",
    "2nd_column_Headline": "SUBSCRIBE TO OUR MAILING LIST",
    "2nd_column_Identifier_text": "",
    "2nd_column_Paragraph": "Add your email to the list and we’ll send you more on how we work and keep you up to date on our latest case studies.",
    "Footer_link_group_1_label": "MAIN",
    "Footer_link_group_2_label": "INFO & ASSISTANCE",
    "Footer_Careers": "Careers",
    "Footer_How_it_works": "How it works",
    "Footer_Pricing": "Pricing",
    "Footer_Service": "Service",
    "Footer_Privacy_Policy": "Privacy Policy",
    "Footer_Whats_CTOaaS": "CTO as a Service",
    "slogan": "Technology tailored to your needs.",
    "Footer_slogan": "Technology tailored \n" + "<br />\n" + "to your needs.",
    "Footer_information_company": "<strong>Moat Builders Artificial Intelligence LTD</strong>\n" +
        "<br><br>\n" +
        "<strong>Registered Office:</strong><br> \n" +
        "20-22 Wenlock Road<br> \n" +
        "London, England<br> \n" +
        "N1 7GU<br> \n" +
        "United Kingdom \n" +
        "<br><br>\n" +
        "<strong>Company Number:</strong> 11474706 \n" +
        "<br><br>\n" +
        "<strong>VAT Number:</strong> GB394187459",
    "Footer_Copyright": "<strong>Moat Builders © 2023</strong> - All rights reserved.",
    "Header_Button_1": "How can we help",
    "NavBar_Pricing_Button": "Pricing",
    "NavBar_Logo_": "Moat Builders",
    "NavBar_NavItem_1": "CTO as a Service",
    "NavBar_NavItem_2": "How it works",
    "NavBar_NavItem_3": "Careers",
    "Careers": "Careers",
    "thank_you_title":"Thank you, {{user_name}}!",
    "thank_you_description":"We appreciate your interest in our services. We will get in touch with you shortly to discuss how we can help you achieve your goals. \n" +
        "<br /><br />\n" +
        "In the meantime, feel free to browse our website. We look forward to hearing from you soon!\n" +
        "<br /><br />\n" +
        "Remember that we produce technology tailored to your needs.\n" +
        "<br /><br />\n" +
        "Best regards,\n" +
        "<br /><br />\n" +
        "<strong>Moat Builders.</strong>",
    "thank_you_cta_label":"Go back to the homepage",
    "confirmation_title": "Great! We're keen to have a chat with you!",
    "confirmation_subtitle": "Warming up for our call...",
    "confirmation_description": "Shortly you'll receive an\n" + "<strong>email</strong>\n" + "with the event details and\n" + "<strong>link</strong>\n" + "to access it.\n" +
        "<br /><br />\n" +
        "In the meantime, why don't you get to know a little more about Moat? Feel free to browse our website.\n" +
        "<br /><br />\n" +
        "See you soon!\n" +
        "<br /><br />\n" +
        "<strong>Moat Builders. </strong>\n" + "<em> Technology tailored to your needs.</em>\n",
    "confirmation_button": "Go back to the homepage",
    "privacy_policy_title": "Privacy Policy",
    "privacy_policy_text": "<em>_Effective Date: 18/12/2023_</em>\n" +
    "<br /><br />\n" +
    "Thank you for visiting our website. This Privacy Policy outlines how we collect, use, and protect your personal information. By using our website, you agree to the terms of this Privacy Policy.\n" +
    "<br /><br />\n" +
    "<strong>## Information We Collect:</strong>\n" +
    "<br /><br />\n" +
    "We may collect personal information that you provide voluntarily when using our website. This may include your name, email address, and any other information you choose to provide.\n" +
    "<br /><br />\n" +
    "<strong>## Use of Personal Information:</strong>\n" +
    "<br /><br />\n" +
    "We use the collected information to:\n" +
    "<br /><br />\n" +
    "1. Provide and improve our services.<br />\n" +
    "2. Respond to your inquiries and communicate with you.<br />\n" +
    "3. Conduct marketing activities on our behalf.<br />\n" +
    "<br /><br />\n" +
    "<strong>## Third Party Processors:</strong>\n" +
    "<br /><br />\n" +
    "Our carefully selected partners and service providers may process personal information about you on our behalf as described below:\n" +
    "<br /><br />\n" +
    "<strong>**Digital Marketing Service Providers:**</strong>\n" +
    "<br /><br />\n" +
    "We periodically appoint digital marketing agents to conduct marketing activity on our behalf, such activity may result in the compliant processing of personal information.  Our appointed data processors include:\n" +
    "<br /><br />\n" +
    "(i) Prospect Global Ltd (trading as Sopro) Reg. UK Co. 09648733. You can contact Sopro and view their privacy policy here: http://sopro.io.  Sopro is registered with the ICO Reg: ZA346877 their Data Protection Officer can be emailed at: dpo@sopro.io.\n" +
    "<br /><br />\n" +
    "<strong>## Your Choices:</strong>\n" +
    "<br /><br />\n" +
    "You have the right to:\n" +
    "<br /><br />\n" +
    "1. Access the personal information we hold about you.<br />\n" +
    "2. Correct inaccuracies in your personal information.<br />\n" +
    "3. Request the deletion of your personal information.<br />\n" +
    "<br /><br />\n" +
    "<strong>## Security:</strong>\n" +
    "<br /><br />\n" +
    "We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.\n" +
    "<br /><br />\n" +
    "<strong>## Changes to this Privacy Policy:</strong>\n" +
    "<br /><br />\n" +
    "We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website.\n" +
    "<br /><br />\n" +
    "<strong>## Contact Us:</strong>\n" +
    "<br /><br />\n" +
    "If you have any questions or concerns about this Privacy Policy, please contact us at <a href='mailto:1337@moat.ai'>1337@moat.ai</a>.\n",
} 
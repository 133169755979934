import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-link", "data-slug"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(_ctx.classes),
    "data-link": _ctx.dataLink,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPath && _ctx.goToPath(...args))),
    "data-slug": _ctx.dataSlug
  }, _toDisplayString(_ctx.label), 11, _hoisted_1))
}
import {en} from "@/app-modules/cms/en";
import {links} from "@/app-modules/cms/links";
import {first, forEach, pickBy, size} from 'lodash';
import router from "@/router";
import axios from "axios";
import {PublicConfig} from "@/app-modules/PublicConfig";
import {cmsContent} from "@/main";
import {UrlManipulator} from "@/router/UrlManipulator";

export class Content {
    // decide which language to use, defaults to imported en
    content: any;
    links: any;
    locale: string = 'en';

    constructor() {
        this.content = { en: en }
        this.links = { en: links }
    }
    async getLinksFromCms(page: number = 1) {
        try {
            const sLinks = await axios.get(`${PublicConfig.cmsUrlApi}/moat-site-links/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${PublicConfig.cmsReadKey}`
                }
            });
            this.setLinksFromData(sLinks.data)

            if(sLinks.data.meta.pagination.pageCount > page) {
                await this.getContentFromCms(page + 1)
            }
        } catch (e) {
            console.error(e)
            this.links =  { en: links }
        }
    }

    async getContentFromCms(page: number = 1) {
        try {
            const sText = await axios.get(`${PublicConfig.cmsUrlApi}/moat-site-contents/?pagination[pageSize]=100&pagination[page]=${page}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${PublicConfig.cmsReadKey}`
                }
            });
            this.setContentFromData(sText.data)

            if(sText.data.meta.pagination.pageCount > page) {
                await this.getContentFromCms(page + 1)
            }
        } catch (e) {
            console.error(e)
            this.content = { en: en }
        }
    }

    setContentFromData(content: any) {
        forEach(content.data, (value, key) => {
            let locale = value['attributes']['locale'] || 'none'
            const slug = value['attributes']['slug']
            const text = value['attributes']['text']

            if (!this.content.hasOwnProperty(<string | number | symbol> locale))  this.content[locale] = {}
            this.content[locale][slug] = text || ''
        })
    }

    setLinksFromData(content: any) {
        forEach(content.data, (value, key) => {
            let locale = value['attributes']['locale'] || 'none'
            const slug = value['attributes']['slug']
            const text = value['attributes']['text']

            if (!this.links.hasOwnProperty(<string | number | symbol> locale))  this.links[locale] = {}
            this.links[locale][slug] = text || ''
        })
    }

    static getTextById(id: string): string {
        try {
            return cmsContent.content[cmsContent.locale][`${id}`]
        } catch (e) {
            console.error(e)
            return ''
        }
    }

    static getLinkById(id: string): string {
        try {
            return cmsContent.links[cmsContent.locale][`${id}`]
        } catch (e) {
            console.error(e)
            return ''
        }
    }

    static countKeysByRegex(regex: RegExp): number {
        try {
            return size(Object.keys(cmsContent.content[cmsContent.locale]).filter((blockKey) => blockKey.match(regex)))
        } catch (e) {
            console.error(e)
            return 0
        }
    }

    static retrieveKeysByRegex(regex: RegExp): string[] {
        try {
            return Object.keys(cmsContent.content[cmsContent.locale]).filter((blockKey) => blockKey.match(regex))
        } catch (e) {
            console.error(e)
            return []
        }
    }

    static search(id: string, text: string) {
        if (!id && !text) return cmsContent.content[cmsContent.locale];
        return pickBy(cmsContent.content[cmsContent.locale], (value, key) => {
            if (id && !key.toLowerCase().includes(id.toLowerCase())) return false;
            return !(text && !value.toLowerCase().includes(text.toLowerCase()));
        });
    }

    static async navigate(id: string) {
        const target = this.getLinkById(id)

        try {
            const config = {} as any
            const rawParams = target.split(',')
            // check if target contains hash:
            if (target.includes('hash:')) {
                // find hash in params
                config.hash = rawParams.find((param: string) => param.includes('hash:'))?.split(':')[1]
            }
            // check if target contains params
            if (target.includes('params:')) {
                // create a new param for each param using the format param:foo=bar
                const params = rawParams.filter((param: string) => param.includes('params:'))?.map((param: string) => {
                    const [key, value] = param.split(':')[1].split('=')
                    return {
                        [key]: value
                    }
                })
                config.params = first(params)
            }
            // check if target contains route:
            if (target.includes('route:')) {
                // find route in params
                config.name = rawParams.find((param: string) => param.includes('route:'))?.split(':')[1]
                await router.push(config);
                return
            }
        } catch (e) {
            console.error(e)
        }

        if (target) {
            // if no route: or hash: found, just navigate to target, check if it is a valid url
            if (!target.includes('http')) {
                console.error('Invalid target: ' + target)
                return
            }
            window.location.href = target
        } else {
            window.location.href = id
        }
    }
}

export const t = (id: string) => {
    return Content.getTextById(id);
}

export const l = async (id: string) => {
    await Content.navigate(id)
}

export const fetchCollectionData = async (filters: any, collection: string)=>{
    let response = null;
    try {
        response = await axios.get(`${PublicConfig.cmsUrlApi}/${collection}/?pagination[pageSize]=100&pagination[page]=1`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${PublicConfig.cmsReadKey}`
            }
        });
    }catch (e){
        console.log(e)
        return undefined;
    }

    if (response != null) {
        let responseData = response['data']['data'];

        return responseData.map((item:any)=>{
            return item['attributes'] //returns records attributes data
        }).filter((item:any)=>{
            let passFilter = false
            Object.keys(filters).forEach((key)=>{
                passFilter = item[key] == filters[key] //checks if collection record contains attribute with the same filter
            })
            return passFilter
        })
    }
}

export const strapiContentValidator = (attributesToCheck: string[], content:any, sectionId:string|null) =>{

    for (const attr of attributesToCheck) {
        const parts = attr.split('.');
        let currentAttr = content;

        for (const part of parts) {
            if (currentAttr == null || currentAttr[part] == null || currentAttr[part] === '') {
                console.warn(`${sectionId} missing content`);
                throw new Error(`${attr} variable is empty.`);
            }
            currentAttr = currentAttr[part];
        }
    }
}

export const fetchData = async (data: {}, sectionId: string, populateQuery = "=*" ) => {

    let response = null;
    const urlNavigator = new UrlManipulator
    urlNavigator.initialize()
    let localeToUse:string = urlNavigator.getLocale();
    let url = `${PublicConfig.cmsUrlApi}/${sectionId}?populate${populateQuery}&locale=${localeToUse}`;

    try {
        response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${PublicConfig.cmsReadKey}`
            }
        });

    } catch (e) {
        console.log(e)
        return undefined;
    }
    if (response != null) {
        let imageKeys: string[] = ['image', 'backgroundImage', 'images'] // not all single types use the same keys. This allows to modify if need it
        let attributes = response.data.data.attributes; 
        if (sectionId == "comparison-table") { 
            return attributes;
        }   
        Object.keys(data).forEach((key) => {
            // @ts-ignore
            if (data[key] == null) {
                // @ts-ignore
                data[key] = attributes[key];
            } else {
                // @ts-ignore
                if ((typeof data[key]) == 'object') {

                    if (imageKeys.includes(key)) {
                        fetchImageData(data, key, attributes);
                    } else {
                        fetchValuesFromObjects(data, key, attributes);
                    }
                }
            }
        })
        return data;
    } else {
        return undefined;
    }
}

const  fetchImageData = function (data:any, key: string, attributes: any) {
    Object.keys(data[key]).forEach((subKey) => {
        let imageItems = null;
        if (attributes[key][subKey].length > 1) { // consider when there is more than one image
            imageItems = attributes[key][subKey].map((item: any) => {
                return item['attributes']
            })
        }
        data[key][subKey] = imageItems != null ? imageItems : attributes[key][subKey]['attributes']
    })
}

const fetchValuesFromObjects = function (data: {}, key: string, attributes: any) {
    // @ts-ignore
    Object.keys(data[key]).forEach((subKey) => {
        // @ts-ignore
        if (data[key][subKey] == null) {
            // @ts-ignore
            data[key][subKey] = attributes[key][subKey]
        } else {
            // @ts-ignore
            data[key][subKey] = attributes[key][subKey]
        }
    })
}

const fetchValuesFromRelationItem = function (data: any) {
    let keysFromItemRelation = ['title', 'description', 'subtitle','cta'] // item relation
    let keysFromFaqRelation = ['question', 'answer'] // faq relation
    let keysFromPositionRelation = ['title', 'slug'] //position relation
    let allKeysFromRelation = [...keysFromItemRelation,...keysFromPositionRelation,...keysFromFaqRelation]
    //need updates to consider new realtion type
    interface relationData {title: string | null, description: string | null, subtitle: string | null, cta: string[] | null, display: boolean, slug: string | null} //allow us to define relation item that can be accessible in template.
    let resultString: string[] = []
    let resultObjects: relationData[] = []

    data.forEach((item: any) => {
        let attributes = item['attributes']
        let values: relationData = {
            title: null,
            description: null,
            subtitle: null,
            cta: null,
            display: false,
            slug: null 
        }

        allKeysFromRelation.forEach((key)=>{
            if(attributes.hasOwnProperty(key)){
                if(attributes[key] != null && attributes[key].length !== 0){
                    // @ts-ignore
                    values[key] = attributes[key]
                }
            }
        })

        if([keysFromItemRelation, keysFromPositionRelation, keysFromFaqRelation].some((keys:string[]) => checkObjectForKeys(values, keys,2))){
            values['display'] = true
            resultObjects.push(values)
        } else {
            resultString.push(attributes['title'] == null ? attributes['description'] : attributes['title'] ) //accessing relation with title and description
        }

    })
    return resultString.length == 0 ? resultObjects : resultString;
}

const checkObjectForKeys = function(obj: any, keys: Array<string>,minAttributes: number){
    let attributesCount = 0;

    for (const key of keys) {
        if (key in obj && obj[key] !== null) {
            attributesCount++;
        }
    }

    return attributesCount >= minAttributes;
}

import { defineComponent } from 'vue';
import { UrlManipulator } from '@/router/UrlManipulator';
import { languages } from "@/app-modules/cms/languagesConfig";

const urlNavigator = new UrlManipulator();
let localeToUsed = '';

interface FallbackData {
  data: {
    attributes: {      
      registered_office_title: string;
      registered_office_content_street: string;
      registered_office_content_city: string;
      registered_office_postal_code: string;
      registered_office_content_country: string;
      company_number_title: string;
      company_number_content: string;
      moat_ltd_section_title: string;
      vat_number_title: string;
      vat_number_content: string;
      main_section_title: string;
      main_service: string;
      main_how_it_works: string;
      main_pricing: string;
      info_and_assistance_title: string;
      info_and_assistance_policy: string;
      info_and_assistance_careers: string;
      slogan: string;
      moat_copyright: string;
      all_rights_reserved: string;
      moat_linkedin_url: string;
    }
  };
}

export default defineComponent({
  data() {
    return {
      scrollHeight: 50,
      onTop: false,
      initialScrollPosition: 0,
      urlManipulator: new UrlManipulator,
      fallbackData: {} as FallbackData,
      moatLtdSectionTitle: '',
      registeredOfficeTitle: '',
      registeredOfficeContentStreet: '',
      registeredOfficeContentCity: '',
      registeredOfficeContentPostalCode: '',
      registeredOfficeContentCountry: '',
      companyNumberTitle: '',
      companyNumberContent: '',
      vatNumberTitle: '',
      vatNumberContent: '',
      mainSectionTitle: '',
      mainService: '',      
      mainHowItWorks: '',
      mainPricing: '',
      infoAndAssistanceTitle: '',
      infoAndAssistancePolicy: '',
      infoAndAssistanceCareers: '',
      slogan: '',
      copyright: '',
      allRightsReserved: '',
      moatLinkedinUrl: ''
    };
  },
  computed: {
    currentYear(): number {
      return new Date().getFullYear();
    }
  },
  async beforeMount() {
    this.urlManipulator.initialize();
    localeToUsed = this.urlManipulator.getLocale();
    try {
      const module = await import(`@/app-modules/cms/fallback/${localeToUsed}/footer.json`);
      this.fallbackData = module.default;
      this.moatLtdSectionTitle = this.fallbackData.data.attributes.moat_ltd_section_title;
      this.registeredOfficeTitle = this.fallbackData.data.attributes.registered_office_title;
      this.registeredOfficeContentStreet = this.fallbackData.data.attributes.registered_office_content_street;
      this.registeredOfficeContentCity = this.fallbackData.data.attributes.registered_office_content_city;
      this.registeredOfficeContentPostalCode = this.fallbackData.data.attributes.registered_office_postal_code;
      this.registeredOfficeContentCountry = this.fallbackData.data.attributes.registered_office_content_country;
      this.companyNumberTitle = this.fallbackData.data.attributes.company_number_title;
      this.companyNumberContent = this.fallbackData.data.attributes.company_number_content;
      this.vatNumberTitle = this.fallbackData.data.attributes.vat_number_title;
      this.vatNumberContent = this.fallbackData.data.attributes.vat_number_content;
      this.mainSectionTitle = this.fallbackData.data.attributes.main_section_title;
      this.mainService = this.fallbackData.data.attributes.main_service;
      this.mainHowItWorks = this.fallbackData.data.attributes.main_how_it_works;
      this.mainPricing = this.fallbackData.data.attributes.main_pricing;
      this.infoAndAssistanceTitle = this.fallbackData.data.attributes.info_and_assistance_title;
      this.infoAndAssistancePolicy = this.fallbackData.data.attributes.info_and_assistance_policy;
      this.infoAndAssistanceCareers = this.fallbackData.data.attributes.info_and_assistance_careers;
      this.slogan = this.fallbackData.data.attributes.slogan;
      this.copyright = this.fallbackData.data.attributes.moat_copyright;
      this.allRightsReserved = this.fallbackData.data.attributes.all_rights_reserved;
      this.moatLinkedinUrl = this.fallbackData.data.attributes.moat_linkedin_url;
    } catch (error) {
      // todo - add gcp logs? discord notifications?       
    }
  },
  methods: {
    navigateClickButton(newRoute: string = '', newHash: string = '', newLocale: string = '') {
      this.urlManipulator.loadPageFromButton(this.$route, this.$router, newRoute, newHash, newLocale);
    },
    l(link: string) {
      window.open(this.moatLinkedinUrl, '_blank');
    },
    goToPath(path:string = '', hash:string = '', locale:string = '') {
      this.urlManipulator.goToPath(path, hash, locale);
    },
  }
});

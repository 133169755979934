import {useRoute, useRouter} from 'vue-router';
import {defaultLocale, languagesDict} from "@/app-modules/cms/languagesConfig";

export interface QueryParameters {
    [key: string]: string;
}

export class UrlManipulator {
    protocol: string = '';
    host: string = '';
    path: string = '';
    query: QueryParameters = {};
    hash: string = '';
    router = useRouter(); // Use Vue Router

    initialize(): void {
        const route = useRoute();
        const currentURL = route.fullPath;
        const url = new URL(currentURL, window.location.origin);

        this.protocol = url.protocol;
        this.host = url.host;
        this.path = url.pathname;
        this.query = Object.fromEntries(url.searchParams.entries());
        this.hash = url.hash;

        this.updateLocalePath();
    }

    updateLocalePath(): void {
        let localeToUse = this.getLocale();
        const localeRegex = /^\/(en-GB|en-AU)\//;

        if (!localeRegex.test(this.path)) {
            this.path = `/${localeToUse}${this.path}`;
            this.updateURL(false);
        } else if (!this.path.startsWith(`/${localeToUse}/`)) {
            this.path = this.path.replace(localeRegex, `/${localeToUse}/`);
            this.updateURL(false);
        }
    }

    updatePath(newPath: string): void {
        this.path = newPath;
        this.updateURL(true);
    }

    updateQuery(newQuery: QueryParameters): void {
        this.query = newQuery;
        this.updateURL(true);
    }

    updateHash(newHash: string): void {
        this.hash = newHash;
        this.updateURL(true);
    }

    updateURL(triggerUpdate: boolean): void {
        const newURL = new URL(`${this.protocol}//${this.host}${this.path}`);

        for (const key in this.query) {
            newURL.searchParams.set(key, this.query[key]);
        }

        newURL.hash = this.hash;

        if (triggerUpdate) {
            this.navigate(newURL.toString());
        }
    }

    navigateToDefaultLanguage(): void {
        let query: QueryParameters = {
            locale: defaultLocale
        }
        this.updateQuery(query);
    }

    getSystemLocale = (): string => {
        return window.navigator.language;
    }

    getLocaleFromUrlOrSystem(): string {

        const pathSegments = window.location.pathname.split('/');
        let locale = pathSegments[1];
        const validLocales = ['en-GB', 'en-AU'];

        if (!validLocales.includes(locale)) {
            locale = '';
        }

        if (locale == undefined || locale == '') {
            let storedLocale = window.localStorage.getItem("locale");
            if (storedLocale == undefined || storedLocale == '') {
                let systemLocale: string = this.getSystemLocale();
                return (systemLocale == undefined || systemLocale == '') ? defaultLocale : systemLocale;
            } else {
                return storedLocale;
            }
        }

        return locale;
    }

    getLocale = () => {
        let locale: string = this.getLocaleFromUrlOrSystem();
        let value: string = locale.toLowerCase();
        // @ts-ignore
        locale = value in languagesDict ? languagesDict[value] : defaultLocale;
        window.localStorage.setItem("locale", locale);
        return locale;
    }

    navigate(newURL: string): void {
        // Use Vue Router's router.push to navigate to the new URL
        this.router.push(newURL);
    }

    // @ts-ignore
    loadPageFromButton(Route, Router, newRoute = '', newHash = '', newLocale = '') {

        if (!newLocale) {
            newLocale = this.getLocale();
        }

        const pathSegments = Route.fullPath.split('/').filter((segment: string) => segment);
        const validLocales = ['en-GB', 'en-AU'];

        if (validLocales.includes(pathSegments[0])) {
            pathSegments.shift();
        }

        const newBasePath = [newLocale, ...pathSegments].join('/');

        const routeObj = {
            name: newRoute || Route.name || '',
            params: { locale: newLocale },
            hash: newHash || Route.hash || ''
        };

        const resolvedRoute = Router.resolve(routeObj);
        window.location.href = `/${newBasePath}`;
    }

    goToPath(path: string = '', hash: string = '', newLocale: string = '') {
        const router = this.router;
        let locale = newLocale || this.getLocale();
        let pathWithLocale = `/${locale}${path}`;
        router.push({ path: pathWithLocale, hash: hash });
    }

}
